import React from 'react';
import { graphql } from 'gatsby';
import cx from 'classnames';
import { connect } from 'react-redux';
import * as JsSearch from 'js-search';
import 'rc-slider/assets/index.css';
import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import moment, { Moment } from 'moment';

import '../BookingEngine/components/styles/datePicker.css';
import * as styles from './styles/tours.module.scss';
import {
  IPageDetails,
  IPageContext,
  ITourList,
  IFilterSelectItem,
  ICurrencyOption,
  IReducers,
  ITourListItem,
  ITourCategories,
  ITourCategory,
  ITranslationsVarious,
} from '../interfaces';
import {
  TourList,
  FilterDropdown,
  FilterSelect,
  FilterSearch,
  Loader,
  TitleArea,
  CheckBox,
  Desktop,
  Mobile,
} from '../components';
import { dropdowns, filterParams } from '../constants';
import withMeta from '../hocs/withMeta';
import { ProductApi, AvailabilityApi, TransportApi } from '../api';
import {
  findDefaultPrice,
  getQueryVariable,
  getQueryVariables,
  getMinutesFromDuration,
  getLangByType,
  getSelectText,
  isTransport,
  findDefaultTransportPrice,
  parseSearch,
  setQueryParameters,
  setQueryParameterNoRefresh,
  formatAvailabilityDate,
  clearQueryParameters,
  formatDuration,
  hasQueryParameters,
} from '../utils';

interface IFilteredTourListItem extends ITourListItem {
  defaultIndex: number;
}

interface IFilterDurationItem extends IFilterSelectItem {
  max: number | null;
}

interface ISelectCategory extends ITourCategory {
  selected: boolean;
}

interface ISortOptions {
  key: string;
  label: string;
}

interface IDurationOptions {
  label: string;
  max: number | null;
}

interface IFilters {
  category: boolean;
  price: boolean;
  duration: boolean;
  language: boolean;
  search: boolean;
}

interface IProps {
  data: {
    contentfulPage: IPageDetails;
    allContentfulTour: ITourList;
    allContentfulTourCategory: ITourCategories;
    contentfulTranslationsVarious: ITranslationsVarious;
  };
  pageContext: IPageContext;
  currency: ICurrencyOption;
  location: {
    search: string;
  };
  lang: string;
}

interface IState {
  tours: IFilteredTourListItem[];
  filters: { [tourId: string]: IFilters };
  dateFilter: { [productId: string]: boolean };
  loading: boolean;
  loadingDates: boolean;
  loadingPrices: boolean;
  dropdownOpen: string | null;
  orderBy: number;
  categories: ISelectCategory[];
  price: number[];
  maxPrice: number;
  duration: IFilterDurationItem[];
  languages: IFilterSelectItem[];
  searchValue: string;
  startDate: Moment | null;
  endDate: Moment | null;
  focusedInput: 'startDate' | 'endDate';
  // Only to force dropdown to update
  datePlaceholder: string;
  checkBox: boolean;
  orderByOptions: ISortOptions[];
  durationOptions: IDurationOptions[];
}

const initialState: IState = {
  tours: [],
  filters: {},
  dateFilter: {},
  loading: false,
  loadingDates: true,
  loadingPrices: false,
  dropdownOpen: null,
  orderBy: 0,
  categories: [],
  price: [0, 0],
  maxPrice: 0,
  duration: [],
  languages: [],
  searchValue: '',
  startDate: null,
  endDate: null,
  focusedInput: 'startDate',
  datePlaceholder: '',
  checkBox: false,
  orderByOptions: [],
  durationOptions: [],
};

class Tours extends React.Component<IProps, IState> {
  readonly state: IState = initialState;

  jsSearch: JsSearch.Search | null = null;
  productApi = new ProductApi();
  availabilityApi = new AvailabilityApi();
  transportApi = new TransportApi();
  performSearch: NodeJS.Timer | null = null;

  UNSAFE_componentWillMount() {
    const orderByOptions = this.createSortingOptions(
      this.props.data.contentfulTranslationsVarious.paramsSortBy.options
    );
    const durationOptions = this.createDurationOptions();
    this.setState({ orderByOptions, durationOptions });
  }

  componentDidMount() {
    this.initializeFilters(true);
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.initializeFilters(true);
    }
  }

  componentWillUnmount() {
    if (this.performSearch) {
      clearTimeout(this.performSearch);
    }
  }

  render() {
    const { data, pageContext, currency } = this.props;
    const { title, carousel } = data.contentfulPage;
    const { paramsShowFilters, paramsNotFound } =
      data.contentfulTranslationsVarious;
    const filteredTours = this.getFilteredTours();
    const { loading, loadingPrices, dropdownOpen, loadingDates } = this.state;
    const showOverlay =
      dropdownOpen !== null && !loading && filteredTours.length > 0;
    return (
      <div>
        <TitleArea title={title}>
          <Desktop>{this.renderFilters()}</Desktop>
          <Mobile>
            <div>
              <CheckBox
                checked={this.state.checkBox}
                toggleCheckbox={this.changeCheckBoxState}
              >
                {paramsShowFilters}
              </CheckBox>
              {this.state.checkBox ? this.renderFilters() : null}
            </div>
          </Mobile>
        </TitleArea>
        <div className={styles.tourList}>
          <div className='centered-content'>
            {loading || loadingDates || this.loadingQueryFilters() ? (
              <Loader />
            ) : filteredTours.length ? (
              <TourList
                tours={filteredTours}
                loading={loadingPrices}
                langPath={pageContext.langPath}
                currency={currency}
                carousel={carousel}
              />
            ) : (
              <p data-cy='Search-No-Results'>{paramsNotFound}</p>
            )}
          </div>
          {showOverlay && !loading && !loadingDates && (
            <div className={styles.overlay} />
          )}
        </div>
      </div>
    );
  }

  changeCheckBoxState = () => {
    const { checkBox } = this.state;
    if (checkBox) {
      this.setState({ checkBox: false });
    } else {
      this.setState({ checkBox: true });
    }
  };

  createSortingOptions = (options: string[]) => {
    const orderByOptions: ISortOptions[] = [];
    options.forEach((option) => {
      const splitOption = option.split('{');
      const label = splitOption[0];
      const key = splitOption[1].split('}')[0];
      orderByOptions.push({ key: key, label: label });
    });
    return orderByOptions;
  };

  createDurationOptions = () => {
    const { paramsDuration } = this.props.data.contentfulTranslationsVarious;
    const hour = 60;
    const day = hour * 24;
    const durationOptions: IDurationOptions[] = [
      { label: '1/2 ' + paramsDuration.singular, max: day / 4 },
      { label: '1 ' + paramsDuration.singular, max: day },
      { label: '2 ' + paramsDuration.plural, max: day * 2 },
      { label: '3 ' + paramsDuration.plural + ' +', max: null },
    ];

    return durationOptions;
  };

  renderFilters() {
    const {
      orderBy,
      categories,
      dropdownOpen,
      loadingPrices,
      duration,
      languages,
      startDate,
      endDate,
      focusedInput,
      datePlaceholder,
      searchValue,
      orderByOptions,
    } = this.state;
    const { contentfulTranslationsVarious } = this.props.data;
    const {
      paramsSortBy,
      paramsDate,
      paramsTypeOfTour,
      paramsDuration,
      paramsLanguage,
      paramsSearch,
      paramsClearAll,
    } = contentfulTranslationsVarious;

    return (
      <>
        <div className='columns is-variable is-2 is-multiline'>
          <div className={cx('column', styles.filter)}>
            <p className={styles.filterTitle}>{paramsSortBy.title}</p>
            <FilterDropdown
              selected={!!orderBy}
              selectedText={orderByOptions[orderBy].label}
              open={dropdownOpen === dropdowns.orderBy}
              toggleOpen={() => this.toggleDropdownOpen(dropdowns.orderBy)}
              defaultButton={true}
            >
              <FilterSelect
                items={this.getSortOptions()}
                select={this.selectOrderBy}
              />
            </FilterDropdown>
          </div>

          <div className={cx('column', styles.filter)}>
            <p className={styles.filterTitle}>{paramsDate.inputLabel}</p>
            <FilterDropdown
              selected={!!startDate}
              selectedText={this.getDateText()}
              open={dropdownOpen === dropdowns.date}
              toggleOpen={this.toggleDateDropdownOpen}
              defaultButton={true}
            >
              <div className='dropdown-calendar'>
                <DayPickerRangeController
                  initialVisibleMonth={null}
                  startDate={startDate}
                  endDate={endDate}
                  onDatesChange={this.updateDateRange}
                  onFocusChange={this.updateDateFocus}
                  focusedInput={focusedInput}
                  onNextMonthClick={() => this.setState({ datePlaceholder })}
                  onPrevMonthClick={() => this.setState({ datePlaceholder })}
                  isOutsideRange={(day) =>
                    isInclusivelyBeforeDay(
                      day,
                      moment(new Date()).subtract(1, 'day')
                    )
                  }
                  minimumNights={0}
                />
                <button onClick={this.clearDateSelection}>
                  {paramsDate.altInputLabel}
                </button>
              </div>
            </FilterDropdown>
          </div>

          <div className={cx('column', styles.filter)}>
            <p className={styles.filterTitle}>{paramsTypeOfTour.inputLabel}</p>
            <FilterDropdown
              selected={categories.filter((c) => c.selected).length > 0}
              selectedText={getSelectText(
                categories,
                paramsTypeOfTour.inputPlaceholder,
                paramsTypeOfTour.altInputLabel
              )}
              open={dropdownOpen === dropdowns.type}
              toggleOpen={() => this.toggleDropdownOpen(dropdowns.type)}
              defaultButton={true}
            >
              <FilterSelect items={categories} select={this.selectCategory} />
            </FilterDropdown>
          </div>

          <div className={cx('column', styles.filter)}>
            <p className={styles.filterTitle}>{paramsDuration.inputLabel}</p>
            <FilterDropdown
              selected={duration.filter((d) => d.selected).length > 0}
              selectedText={getSelectText(
                duration,
                paramsDuration.inputPlaceholder,
                paramsDuration.altInputLabel
              )}
              open={dropdownOpen === dropdowns.duration}
              toggleOpen={() => this.toggleDropdownOpen(dropdowns.duration)}
              defaultButton={true}
            >
              {loadingPrices ? (
                this.renderLoading()
              ) : (
                <FilterSelect items={duration} select={this.selectDuration} />
              )}
            </FilterDropdown>
          </div>

          <div className={cx('column', styles.filter)}>
            <p className={styles.filterTitle}>{paramsLanguage.title}</p>
            <FilterDropdown
              selected={languages.filter((l) => l.selected).length > 0}
              selectedText={getSelectText(
                languages,
                paramsLanguage.placeholder,
                paramsDuration.altInputLabel
              )}
              open={dropdownOpen === dropdowns.language}
              toggleOpen={() => this.toggleDropdownOpen(dropdowns.language)}
              defaultButton={true}
            >
              {loadingPrices ? (
                this.renderLoading()
              ) : (
                <FilterSelect items={languages} select={this.selectLanguage} />
              )}
            </FilterDropdown>
          </div>

          <div className={cx('column', styles.search)}>
            <p className={styles.filterTitle}>{paramsSearch.inputLabel}</p>

            <FilterSearch
              value={searchValue}
              onChange={this.updateSearch}
              placeholder={paramsSearch.inputPlaceholder}
            />
          </div>
        </div>
        <button
          className={cx(styles.filterTitle, styles.clearFilters)}
          onClick={() => this.initializeFilters()}
        >
          {paramsClearAll}
        </button>
      </>
    );
  }

  renderLoading() {
    return (
      <div className={styles.loadingFilter}>
        <p>{this.props.data.contentfulTranslationsVarious.loading}</p>
      </div>
    );
  }
  updatePriceRange = (price: number[]) => {
    this.setState({ price });
  };

  updateDateRange = (d: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    const { startDate, endDate } = this.state;
    let end = d.endDate;
    if (startDate && endDate) {
      end = null;
    }
    this.setState({ startDate: d.startDate, endDate: end }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.closeDropdown(dropdowns.date);
        this.filterDates();
      }
    });
  };

  clearDateSelection = () => {
    this.setState(
      { startDate: null, endDate: null, focusedInput: 'startDate' },
      this.filterDates
    );
  };

  updateDateFocus = (focus: 'startDate' | 'endDate' | null) => {
    this.setState({ focusedInput: focus || 'startDate' });
  };

  getSortOptions = () => {
    const { orderBy, loadingPrices, orderByOptions } = this.state;
    const options = loadingPrices ? [orderByOptions[0]] : orderByOptions;

    return options.map((opt, index) => ({
      title: opt.label,
      selected: orderBy === index,
    }));
  };

  getFilteredTours = () => {
    const { tours, filters, dateFilter } = this.state;
    return tours.filter((tour) => {
      if (tour.contentfulTour.slug == 'icelandair') return null;
      const { id, productId } = tour.contentfulTour;
      const filter = filters[id];
      const date = productId && dateFilter[productId];
      return (
        date &&
        filter.category &&
        filter.price &&
        filter.duration &&
        filter.language &&
        filter.search
      );
    });
  };

  getDateText = () => {
    const { startDate, endDate } = this.state;
    const { paramsDate } = this.props.data.contentfulTranslationsVarious;
    if (startDate && endDate && !startDate.isSame(endDate, 'day')) {
      return `${startDate.format('DD/MM')} - ${endDate.format('DD/MM')}`;
    }
    if (startDate) {
      return startDate.format('DD/MM');
    }
    return paramsDate.inputPlaceholder;
  };

  parseQueryDates = (param: string) => {
    const dateStr = getQueryVariable(param);
    if (dateStr) {
      const date = moment(dateStr);
      if (!date.isBefore(moment(), 'day')) {
        return date;
      }
    }
    return null;
  };

  toggleDropdownOpen = (id: string) => {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: dropdownOpen === id ? null : id });
  };

  toggleDateDropdownOpen = () => {
    const { dropdownOpen, startDate, endDate } = this.state;
    if (dropdownOpen === dropdowns.date && startDate && !endDate) {
      this.filterDates();
    }
    this.toggleDropdownOpen(dropdowns.date);
  };

  closeDropdown = (id: string) => {
    const { dropdownOpen } = this.state;
    if (dropdownOpen === id) {
      this.setState({ dropdownOpen: null });
    }
  };

  selectOrderBy = (index: number) => {
    this.closeDropdown(dropdowns.orderBy);
    this.setState({ orderBy: index }, this.sortTours);
  };

  selectCategory = (index: number) => {
    const { categories } = this.state;
    categories[index].selected = !categories[index].selected;
    this.setState({ categories }, this.filterCategories);
  };

  selectDuration = (index: number) => {
    const { duration } = this.state;
    duration[index].selected = !duration[index].selected;
    this.setState({ duration }, this.filterDuration);
  };

  selectLanguage = (index: number) => {
    const { languages } = this.state;
    languages[index].selected = !languages[index].selected;
    this.setState({ languages }, this.filterLanguage);
  };

  updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    this.setState({ searchValue });
    if (this.performSearch) {
      clearTimeout(this.performSearch);
    }
    this.performSearch = setTimeout(() => this.searchTours(searchValue), 500);
  };

  loadingQueryFilters = () => {
    return (
      this.state.loadingPrices &&
      hasQueryParameters([
        filterParams.orderBy,
        filterParams.priceMax,
        filterParams.priceMin,
        filterParams.duration,
        filterParams.language,
      ])
    );
  };

  sortTours = () => {
    this.setState({ loading: true });
    const { tours, orderBy, orderByOptions } = this.state;
    const orderByKey = orderByOptions[orderBy].key;

    if (orderByKey) {
      if (orderByKey === 'popular') {
        tours.sort((a, b) => {
          const indexA = a.contentfulTour.popularIndex || 1000;
          const indexB = b.contentfulTour.popularIndex || 1000;
          return indexA < indexB ? -1 : indexA > indexB ? 1 : 0;
        });
      } else if (orderByKey === 'price-hl') {
        tours.sort((a, b) => {
          const priceA = this.getPrice(a);
          const priceB = this.getPrice(b);
          return priceA > priceB ? -1 : priceA < priceB ? 1 : 0;
        });
      } else if (orderByKey === 'price-lh') {
        tours.sort((a, b) => {
          const priceA = this.getPrice(a);
          const priceB = this.getPrice(b);
          return priceA > priceB ? 1 : priceA < priceB ? -1 : 0;
        });
      } else if (orderByKey === 'duration-sl') {
        tours.sort((a, b) => {
          let durationA =
            a.product && a.product.Duration
              ? getMinutesFromDuration(a.product.Duration)
              : 0;
          if (durationA === 0) {
            durationA = a.contentfulTour.durationMinutes || 0;
          }
          let durationB =
            b.product && b.product.Duration
              ? getMinutesFromDuration(b.product.Duration)
              : 0;
          if (durationB === 0) {
            durationB = b.contentfulTour.durationMinutes || 0;
          }
          return durationA > durationB ? 1 : durationA < durationB ? -1 : 0;
        });
      } else if (orderByKey === 'duration-ls') {
        tours.sort((a, b) => {
          let durationA =
            a.product && a.product.Duration
              ? getMinutesFromDuration(a.product.Duration)
              : 0;
          if (durationA === 0) {
            durationA = a.contentfulTour.durationMinutes || 0;
          }
          let durationB =
            b.product && b.product.Duration
              ? getMinutesFromDuration(b.product.Duration)
              : 0;
          if (durationB === 0) {
            durationB = b.contentfulTour.durationMinutes || 0;
          }
          return durationA > durationB ? -1 : durationA < durationB ? 1 : 0;
        });
      }
    }

    setQueryParameterNoRefresh(
      filterParams.orderBy,
      orderBy !== 0 ? orderByKey : ''
    );

    this.setState({ tours, loading: false });
  };

  filterDates = async () => {
    this.setState({ loadingDates: true });
    const { startDate, endDate, dateFilter } = this.state;
    const { lang } = this.props;
    const { edges } = this.props.data.allContentfulTour;
    const contentfulTours = edges.map((edge) => edge.node);
    Object.keys(dateFilter).map((key) => (dateFilter[key] = true));

    if (startDate) {
      const available = await this.availabilityApi.searchForAvailability(
        startDate,
        endDate || startDate,
        contentfulTours.map((tour) => tour.productId),
        lang
      );
      if (available !== null) {
        Object.keys(dateFilter).map((key) => (dateFilter[key] = false));
        available.forEach((tour) => (dateFilter[tour.Id] = true));
        // No availability check for transport
        contentfulTours.forEach((tour) => {
          if (isTransport(tour.type)) {
            dateFilter[tour.productId] = true;
          }
        });
      }
    }

    setQueryParameterNoRefresh(
      filterParams.start,
      startDate ? formatAvailabilityDate(startDate) : ''
    );
    setQueryParameterNoRefresh(
      filterParams.end,
      endDate ? formatAvailabilityDate(endDate) : ''
    );

    this.setState({ loadingDates: false, dateFilter });
  };

  filterCategories = () => {
    this.setState({ loading: true });
    const { categories, tours, filters } = this.state;
    const activeCategories = categories
      .filter((cat) => cat.selected)
      .map((cat) => cat.id);
    Object.keys(filters).map((key) => (filters[key].category = true));

    if (activeCategories.length) {
      tours.forEach((tour) => {
        const { contentfulTour } = tour;
        let matches = false;

        if (contentfulTour.category) {
          for (const cat of contentfulTour.category) {
            if (activeCategories.includes(cat.id)) {
              matches = true;
              break;
            }
          }
        }

        filters[contentfulTour.id].category = matches;
      });
    }

    setQueryParameters(
      filterParams.type,
      categories.filter((cat) => cat.selected).map((cat) => cat.slug)
    );

    this.setState({ filters, loading: false });
  };
  filterPrice = () => {
    this.setState({ loading: true });

    const { price, filters, tours, maxPrice } = this.state;

    Object.keys(filters).map((key) => (filters[key].price = true));

    tours.forEach((tour) => {
      const { product, contentfulTour, transportPrice } = tour;

      let matches = false;

      if (product && product.DiscountPrice) {
        if (
          product.DiscountPrice >= price[0] &&
          product.DiscountPrice <= price[1]
        ) {
          matches = true;
        }
      } else if (product && product.DefaultPrice) {
        if (
          product.DefaultPrice >= price[0] &&
          product.DefaultPrice <= price[1]
        ) {
          matches = true;
        }
      } else if (transportPrice) {
        if (transportPrice >= price[0] && transportPrice <= price[1]) {
          matches = true;
        }
      } else {
        matches = price[0] === 0;
      }

      filters[contentfulTour.id].price = matches;
    });

    setQueryParameterNoRefresh(
      filterParams.priceMin,

      price[0] && price[0] > 0 ? price[0].toString() : ''
    );

    setQueryParameterNoRefresh(
      filterParams.priceMax,

      price[1] && price[1] < maxPrice ? price[1].toString() : ''
    );

    this.setState({ filters, loading: false });
  };

  filterDuration = () => {
    this.setState({ loading: true });
    const { duration, filters, tours } = this.state;
    const activeDurations = duration
      .map((d, index) => ({
        ...d,
        min: index > 0 ? duration[index - 1].max : 0,
      }))
      .filter((d) => d.selected);
    Object.keys(filters).map((key) => (filters[key].duration = true));

    if (activeDurations.length) {
      tours.forEach((tour) => {
        const { contentfulTour, product } = tour;
        let matches = false;
        let minutes = 0;

        if (product && product.Duration) {
          minutes = getMinutesFromDuration(product.Duration);
        } else if (contentfulTour.durationMinutes) {
          minutes = contentfulTour.durationMinutes;
        }
        for (const active of activeDurations) {
          const matchesMin = !active.min || minutes >= active.min;
          const matchesMax = !active.max || minutes <= active.max;
          if (matchesMin && matchesMax) {
            matches = true;
            break;
          }
        }
        filters[contentfulTour.id].duration = matches;
      });
    }

    setQueryParameters(
      filterParams.duration,
      duration.filter((d) => d.selected).map((d) => formatDuration(d.title))
    );

    this.setState({ filters, loading: false });
  };

  filterLanguage = () => {
    this.setState({ loading: true });
    const { languages, tours, filters } = this.state;
    const activeLanguages = languages
      .filter((lang) => lang.selected)
      .map((lang) => lang.title.toLowerCase());
    Object.keys(filters).map((key) => (filters[key].language = true));

    if (activeLanguages.length && !activeLanguages.includes('english')) {
      tours.forEach((tour) => {
        const { contentfulTour, product } = tour;
        let matches = false;

        if (product && product.Guidance) {
          const guidedLang = getLangByType(product.Guidance);
          for (const lang of guidedLang) {
            if (activeLanguages.includes(lang.toLocaleLowerCase())) {
              matches = true;
              break;
            }
          }
        }

        filters[contentfulTour.id].language = matches;
      });
    }

    setQueryParameters(filterParams.language, activeLanguages);

    this.setState({ filters, loading: false });
  };

  searchTours = (searchValue: string) => {
    this.setState({ loading: true });
    const { filters } = this.state;

    if (this.jsSearch && searchValue !== '') {
      Object.keys(filters).map((key) => (filters[key].search = false));
      const tours = this.jsSearch.search(searchValue) as ITourListItem[];
      tours.forEach((tour) => (filters[tour.contentfulTour.id].search = true));
      setQueryParameterNoRefresh(filterParams.search, searchValue);
    } else {
      Object.keys(filters).map((key) => (filters[key].search = true));
    }
    this.setState({ filters, loading: false });
  };

  initializeFilters = (useQueryParams = false) => {
    const {
      allContentfulTour,
      allContentfulTourCategory,
      contentfulTranslationsVarious,
    } = this.props.data;
    const { orderByOptions, durationOptions } = this.state;
    const { paramsLanguage } = contentfulTranslationsVarious;
    const contentfulTours = allContentfulTour.edges.map((edge) => edge.node);

    if (!useQueryParams) {
      clearQueryParameters();
    }

    const tours = contentfulTours.map((tour, index) => ({
      contentfulTour: tour,
      product: null,
      defaultIndex: index,
    }));

    const filters: { [tourId: string]: IFilters } = {};
    const dateFilter: { [productId: string]: boolean } = {};
    contentfulTours.forEach((tour) => {
      filters[tour.id] = {
        category: true,
        price: true,
        duration: true,
        language: true,
        search: true,
      };
      if (tour.productId) {
        dateFilter[tour.productId] = true;
      }
    });

    const querySort = getQueryVariable(filterParams.orderBy);
    let orderBy = 0;
    orderByOptions.forEach((opt, index) => {
      if (opt.key === querySort) {
        orderBy = index;
      }
    });
    const queryMin = parseInt(
      getQueryVariable(filterParams.priceMin) || '0',

      10
    );

    const queryMax = parseInt(
      getQueryVariable(filterParams.priceMax) || '0',

      10
    );
    const queryCategories = getQueryVariables(filterParams.type);
    const categories = allContentfulTourCategory.edges.map((cat) => ({
      ...cat.node,
      selected: useQueryParams && queryCategories.includes(cat.node.slug),
    }));

    const queryDuration = getQueryVariables(filterParams.duration);
    const duration = durationOptions.map((opt) => ({
      title: opt.label,
      max: opt.max,
      selected:
        useQueryParams && queryDuration.includes(formatDuration(opt.label)),
    }));

    const queryLang = getQueryVariables(filterParams.language);
    const languages = paramsLanguage.options.map((lang) => ({
      title: lang,
      selected: useQueryParams && queryLang.includes(lang.toLowerCase()),
    }));

    const search = new JsSearch.Search(['contentfulTour', 'id']);
    search.addIndex(['contentfulTour', 'title']);
    search.addIndex(['contentfulTour', 'productNumber']);
    search.addIndex(['contentfulTour', 'summary']);
    search.addDocuments(tours);
    this.jsSearch = search;

    const start = this.parseQueryDates(filterParams.start);
    const end = this.parseQueryDates(filterParams.end);
    const startDate = useQueryParams ? start : null;
    const endDate = useQueryParams ? end : null;
    const searchValue = useQueryParams
      ? parseSearch(getQueryVariable(filterParams.search) || '')
      : '';

    this.setState(
      {
        ...initialState,
        tours,
        filters,
        dateFilter,
        orderBy,
        categories,
        duration,
        languages,
        searchValue,
        startDate,
        endDate,
        orderByOptions,
      },
      () => {
        if (orderBy === 0) {
          this.sortTours();
        }
        this.filterDates();
        this.filterCategories();
        this.getDefaultPrices(queryMin, queryMax);

        this.searchTours(searchValue);
      }
    );
  };

  getPrice = (tour: IFilteredTourListItem) => {
    let price =
      tour.product && tour.product.DefaultPrice ? tour.product.DefaultPrice : 0;
    price =
      tour.product && tour.product.DiscountPrice
        ? tour.product.DiscountPrice
        : price;
    price = price === 0 && tour.transportPrice ? tour.transportPrice : price;
    return price;
  };
  getDefaultPrices = async (priceMin?: number, priceMax?: number) => {
    this.setState({ loadingPrices: true });

    const { tours } = this.state;
    const { lang } = this.props;

    const { edges } = this.props.data.allContentfulTour;

    const contentfulTours = edges.map((edge) => edge.node);

    let maxPrice = 0;

    const defaultPrices = await this.productApi.getDefaultPrices(
      contentfulTours.map((tour) => tour.productId),

      this.props.currency.value,
      lang
    );

    if (defaultPrices) {
      tours.forEach((tour) => {
        const defPrice = findDefaultPrice(
          defaultPrices,

          tour.contentfulTour.productId
        );

        if (defPrice) {
          if (defPrice.DiscountPrice && defPrice.DiscountPrice > maxPrice) {
            maxPrice = Math.ceil(defPrice.DiscountPrice);
          } else if (defPrice.DefaultPrice > maxPrice) {
            maxPrice = Math.ceil(defPrice.DefaultPrice);
          }
        }

        tour.product = defPrice;
      });
    }

    maxPrice = await this.getTransportPrices(tours, maxPrice);

    const validMinMax = !priceMin || !priceMax || priceMin <= priceMax;

    const min =
      validMinMax && priceMin && priceMin > 0 && priceMin < maxPrice
        ? priceMin
        : 0;

    const max =
      validMinMax && priceMax && priceMax > 0 && priceMax < maxPrice
        ? priceMax
        : maxPrice;

    this.setState(
      {
        tours,

        loadingPrices: false,

        maxPrice,

        price: [min, max],
      },

      () => {
        this.sortTours();

        this.filterPrice();

        this.filterDuration();

        this.filterLanguage();
      }
    );
  };
  getTransportPrices = async (tours: ITourListItem[], maxPrice: number) => {
    const { lang } = this.props;
    const defaultPrices = await this.transportApi.getDefaultPrices(
      tours.map((tour) => tour.contentfulTour.productId),
      this.props.currency.value,
      lang
    );

    let newMax = maxPrice;
    if (defaultPrices) {
      tours.forEach((tour) => {
        const transport = findDefaultTransportPrice(
          defaultPrices,
          tour.contentfulTour.productId
        );
        if (transport) {
          if (transport.DefaultPrice) {
            tour.transportPrice = transport.DefaultPrice;
            if (transport.DefaultPrice > newMax) {
              newMax = Math.ceil(transport.DefaultPrice);
            }
          }
          if (transport.KeyPhoto) {
            tour.transportImage = transport.KeyPhoto;
          }
        }
      });
    }
    return newMax;
  };
}

export const query = graphql`
  query ToursQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageDetails
    }
    allContentfulTour(
      filter: {
        node_locale: { eq: $locale }
        title: { ne: "ekki eyða" }
        type: { ne: "flybus" }
      }
    ) {
      ...TourList
    }
    contentfulTranslationsVarious(node_locale: { eq: $locale }) {
      ...TranslationsVarious
    }
    allContentfulTourCategory(
      sort: { fields: title }
      filter: { node_locale: { eq: $locale } }
    ) {
      ...TourCategories
    }
  }
`;

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { lang } = reducers.translationReducers;
  return { currency, lang };
};

export default connect(mapStateToProps)(withMeta(Tours));
